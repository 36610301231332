<template>
  <div class="w-100p header">
    <div class="w-100p df ai-c jc-fs p-b-16 p-t-16 p-r-16 p-l-16">
      <div class="df ai-c jc-fs m-r-12">
        <i class="el-icon-date" />
        <div class="date">{{ date }}</div>
      </div>
      <div class="df ai-c jc-fs window-time-container">
        <i class="el-icon-time" />
        <div class="window-time">{{ time }}</div>
      </div>
      <div
        class="icon-container"
        :class="{ active: expanded }"
        @click="$emit('expand')"
      >
        <i class="el-icon-arrow-down" />
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DAY, GET_TIME } from '@/constants/date'
import { getTime } from '@/core'

export default {
  name: 'CardShippingHeader',
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    windowFrom: {
      type: String,
      default: undefined,
    },
    windowTo: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    time() {
      if (this.windowFrom && this.windowTo) {
        return (
          getTime(this.windowFrom, GET_TIME) +
          ' - ' +
          getTime(this.windowTo, GET_TIME)
        )
      }

      return 'Время не определенно'
    },
    date() {
      if (this.windowFrom && this.windowTo) {
        return getTime(this.windowTo, GET_DAY)
      }

      return 'Время не определенно'
    },
  },

  methods: {},
}
</script>

<style scoped lang="sass">
.header
  border-bottom: 1px solid #ebeef5
.date
  margin-left: 4px
  color: #303133
  font-size: 14px
  font-weight: 500
  font-family: Roboto, sans-serif
.window-time-container
  flex: 1 1
.window-time
  margin-left: 4px
  color: #db6d39
  font-size: 14px
  font-weight: 500
  font-family: Roboto, sans-serif
.icon-container
  > i
    display: block
    transition: all 0.4s ease
.active
  > i
    transform: rotateZ(-180deg)
</style>
