var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100p header" }, [
    _c(
      "div",
      { staticClass: "w-100p df ai-c jc-fs p-b-16 p-t-16 p-r-16 p-l-16" },
      [
        _c("div", { staticClass: "df ai-c jc-fs m-r-12" }, [
          _c("i", { staticClass: "el-icon-date" }),
          _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
        ]),
        _c("div", { staticClass: "df ai-c jc-fs window-time-container" }, [
          _c("i", { staticClass: "el-icon-time" }),
          _c("div", { staticClass: "window-time" }, [_vm._v(_vm._s(_vm.time))]),
        ]),
        _c(
          "div",
          {
            staticClass: "icon-container",
            class: { active: _vm.expanded },
            on: {
              click: function ($event) {
                return _vm.$emit("expand")
              },
            },
          },
          [_c("i", { staticClass: "el-icon-arrow-down" })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }